<template>
  <div>
    <div class="row table-wrapper px-20">
      <div class="content-heading d-flex justify-content-between align-items-center">
        <h3 class="text-primary font-22">Contracts</h3>
        <div class="d-lg-flex d-md-block d-block mt-md-10">
          <div class="dropdown custom-dropdown mr-50 mb-md-10 mb-20 mb-lg-0">
            <button id="dropdownMenuButton" aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle"
                    data-toggle="dropdown" type="button">
              {{ searchDict.searchField.header || 'Select column' }}
            </button>
            <div aria-labelledby="dropdownMenuButton" class="dropdown-menu"
                 style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(0px, 40px, 0px);"
                 x-placement="bottom-start">
              <a v-show="searchDict.searchField" class="dropdown-item" @click="selectColumn('')">Select column</a>
              <a v-for="(searchConfig,_index) in tableSearchConfig" :key="_index"
                 class="dropdown-item"
                 @click="selectColumn(searchConfig)">{{ searchConfig.header }}</a>
            </div>
          </div>
          <div class="search-form">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'text'"
                   v-model.trim="searchDict.searchText"
                   class="form-control"
                   placeholder="Search" type="text" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                   v-model.trim="searchDict.fromDate"
                   class="form-control mr-2" type="date" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date'"
                   v-model.trim="searchDict.date"
                   class="form-control"
                   type="date" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                   v-model.trim="searchDict.toDate"
                   class="form-control"
                   type="date" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number'"
                   v-model.trim="searchDict.searchText"
                   class="form-control"
                   placeholder="Search" type="number" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                   v-model.trim="searchDict.fromNumber"
                   class="form-control mr-2" placeholder="From" type="number" @keyup.enter="applySearch()">
            <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                   v-model.trim="searchDict.toNumber"
                   class="form-control" placeholder="To" type="number" @keyup.enter="applySearch()">
            <input v-show="!searchDict.searchField" v-model.trim="searchDict.searchText"
                   class="form-control" placeholder="Search" type="text" @keyup.enter="applySearch()">
            <div>
              <button class="btn btn-primary" @click="applySearch()">
                <img alt="Search" src="@/assets/img/icons/search-glass.svg">
              </button>
              <button class="btn btn-outline-primary ml-2" @click="clearSearch()">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
        <tr>
          <th v-for="(item, _i) in tableHeaders" :key="_i"
              @click="applyOrdering(item.db_representation, item.orderColumn)">
            <span class="pr-1">
              {{ item.title }} <br> {{ item.subtitle }}
            </span>
            <span
              v-show="orderColumnDbRepresentation === item.db_representation"
              :class="[orderBy === '-' ? 'descending': 'ascending']">
            </span>
          </th>
        </tr>
        </thead>
        <tbody v-show="loading">
        <tr>
          <td :colspan="tableHeaders.length">
            <FBTableLoader :columns="tableHeaders.length" :height="300" :primaryColor="'#d3d3d3'" :rows="10"
                           class="col-12"></FBTableLoader>
          </td>
        </tr>
        </tbody>
        <tbody v-show="!loading">
        <tr v-for="(rowData, _rowIndex) in tableData" :key="_rowIndex">
          <td v-for="(item, _i) in rowData" :key="_i">
            <span v-html="item"></span>
          </td>
        </tr>
        <tr v-if="tableData.length === 0">
          <td :colspan="tableHeaders.length">
            No data available
          </td>
        </tr>
        </tbody>
      </table>
      <div class="container pl-0 pr-0">
        <div class="">
          <div class="d-lg-flex d-block justify-content-between mt-15 mb-45">
            <div>
              <select v-model="paginateDict.dataPerPage" class="form-control admin-input justify-content-start"
                      @change="getContentsFromServer()">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
            <div>
              <nav aria-label="Page navigation example">
                <ul class="pagination pagination-lg justify-content-end">
                  <li :class="{disabled: !paginateDict.firstPage, 'page-item':true}">
                    <a class="page-link" @click="getPaginatedData(paginateDict.firstPage)">First</a>
                  </li>
                  <li v-show="paginateDict.previousPageNumber > 0"
                      :class="{disabled: !paginateDict.previousUrl, 'page-item':true}">
                    <a class="page-link"
                       @click="getPaginatedData(paginateDict.previousUrl)">{{ paginateDict.previousPageNumber }}</a>
                  </li>
                  <li class="page-item disabled"><a class="page-link" href="#">{{ paginateDict.currentPageNumber }}</a>
                  </li>
                  <li v-show="paginateDict.nextPageNumber > 0"
                      :class="{disabled: !paginateDict.nextUrl, 'page-item':true}">
                    <a class="page-link" @click="getPaginatedData(paginateDict.nextUrl)">{{
                        paginateDict.nextPageNumber
                      }}</a>
                  </li>
                  <li :class="{disabled: !paginateDict.lastPage, 'page-item':true}">
                    <a class="page-link" @click="getPaginatedData(paginateDict.lastPage)">Last</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import UtilityMixin from '@/mixins/utility-mixin'
import _ from 'lodash'
import { FBTableLoader } from '@/plugins/content-loader/core/components'

export default {
  name: 'ContractsTable',
  components: { FBTableLoader },
  props: {
    country: {
      required: false,
      default: function () {
        return null
      },
      type: Object
    }
  },
  data () {
    return {
      loading: true,
      tableHeaders: [
        {
          title: 'Contract',
          subtitle: 'Number',
          db_representation: 'contract_no', // user for apply sort
          orderColumn: 'contract_no' // apply ordering js level
        },
        {
          title: 'Delivery',
          subtitle: 'Month',
          db_representation: 'shipment_start_date',
          orderColumn: 'shipment_start_date'
        },
        {
          title: 'NKG',
          subtitle: 'Exporter',
          db_representation: 'exporter',
          orderColumn: 'exporter.name'
        },
        {
          title: 'NKG',
          subtitle: 'Importer',
          db_representation: 'importer',
          orderColumn: 'importer.name'
        },
        {
          title: '',
          subtitle: 'Customer',
          db_representation: 'customer',
          orderColumn: 'customer.name'
        },
        {
          title: '',
          subtitle: 'Containers',
          db_representation: 'bol_contracts:containers:container_no',
          orderColumn: 'bol_contracts:containers:container_no'
        },
        {
          title: 'Country',
          subtitle: 'of Origin',
          db_representation: 'country_of_origin',
          orderColumn: 'exporter.parent'
        },
        {
          title: 'Net',
          subtitle: 'Weight (kgs)',
          db_representation: 'weight',
          orderColumn: 'weight'
        },
        {
          title: '',
          subtitle: 'Regions',
          db_representation: 'region_groups_region',
          orderColumn: 'region_groups.region.name'
        },
        {
          title: 'Farmer',
          subtitle: 'Group/Label',
          db_representation: 'region_groups_group',
          orderColumn: 'region_groups.group.name'
        },
        {
          title: '',
          subtitle: 'Farmer Subgroup(s)',
          db_representation: 'region_groups_sub_group',
          orderColumn: 'region_groups.sub_group.sub_group_name'
        }
      ],
      tableSearchConfig: [
        {
          header: 'Contract Number',
          db_representation: 'contract_no',
          widget_type: 'text'
        },
        {
          header: 'Delivery Month',
          db_representation: 'shipment_end_date,shipment_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'NKG Exporter',
          db_representation: 'exporter__name',
          widget_type: 'text'
        },
        {
          header: 'NKG Importer',
          db_representation: 'importer__name',
          widget_type: 'text'
        },
        {
          header: 'Customer',
          db_representation: 'customer__name',
          widget_type: 'text'
        },
        {
          header: 'Containers',
          db_representation: 'bol_contracts__containers__container_no',
          widget_type: 'text'
        },
        {
          header: 'Country of Origin',
          db_representation: 'exporter__parent__name',
          widget_type: 'text'
        },
        {
          header: 'Net Weight (kgs)',
          db_representation: 'weight',
          widget_type: 'number_range'
        },
        {
          header: 'Regions',
          db_representation: 'region_groups__region__name',
          widget_type: 'text'
        },
        {
          header: 'Farmer Group/Label',
          db_representation: 'region_groups__group__name',
          widget_type: 'text'
        },
        {
          header: 'Farmer Subgroup(s)',
          db_representation: 'region_groups__sub_group__name',
          widget_type: 'text'
        }
      ],
      tableData: [],
      searchDict: {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      },
      paginateDict: {
        nextUrl: null,
        previousUrl: null,
        currentPage: 1,
        totalPage: 1,
        firstPage: null,
        lastPage: null,
        remainingCount: 0,
        nextOffset: 0,
        totalCount: 0,
        dataPerPage: 5,
        previousPageNumber: 0,
        currentPageNumber: 0,
        nextPageNumber: 0
      },
      apiPrefix: 'v1/contracts',
      orderColumnDbRepresentation: 'contract_no',
      isSecondaryContract: false,
      contractRegion: '',
      contractGroup: '',
      contractSubGroup: ''
    }
  },
  mixins: [validationMixin, UtilityMixin],
  validations: {
    searchDict: {
      searchField: {
        required
      }
    }
  },
  methods: {
    selectColumn (searchConfig) {
      this.searchDict = {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }

      this.searchDict.searchField = searchConfig
    },
    clearSearch () {
      this.searchDict = {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }

      this.getContentsFromServer()
    },
    generatePagination (data) {
      try {
        this.paginateDict.nextUrl = _.get(data, 'next') || null
        this.paginateDict.previousUrl = _.get(data, 'previous') || null
        this.paginateDict.totalPage = _.get(data, 'total_page') || 0
        this.paginateDict.nextOffset = _.get(data, 'next_offset') || 0
        this.paginateDict.currentPage = _.get(data, 'current_page') || 0
        this.paginateDict.totalCount = _.get(data, 'count') || 0
        this.paginateDict.remainingCount = _.get(data, 'remaining_count') || 0
        this.paginateDict.firstPage = `/api/${this.apiPrefix}/?limit=${this.paginateDict.dataPerPage}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        let offset = 0
        for (let i = 1; i < this.paginateDict.totalPage; i++) {
          offset += _.parseInt(this.paginateDict.dataPerPage)
        }
        this.paginateDict.lastPage = `/api/${this.apiPrefix}/?offset=${offset}&limit=${this.paginateDict.dataPerPage}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        if (_.get(this.country, 'public_code') || null) {
          this.paginateDict.firstPage += `&search=1&exporter__parent__public_code=${this.country.public_code}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
          this.paginateDict.lastPage += `&search=1&exporter__parent__public_code=${this.country.public_code}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        }
        // Page number
        this.paginateDict.previousPageNumber = this.paginateDict.previousUrl ? this.paginateDict.currentPage - 1 : 0
        this.paginateDict.currentPageNumber = this.paginateDict.currentPage
        this.paginateDict.nextPageNumber = this.paginateDict.nextUrl ? this.paginateDict.currentPage + 1 : 0
      } catch (e) {
        this.errorLog(e)
      }
    },
    prepareTableContents () {
      try {
        this.tableData = []
        const contracts = [] // use for eliminate duplicate row from list
        let _content = this.$store.getters.getTraceabilityContractsContents
        const orderBy = this.orderBy === '-' ? 'desc' : 'asc'
        _content = _.orderBy(_content, [this.orderColumn], [orderBy])
        _content = _.uniqWith(_content, _.isEqual)

        for (const _data of _content) {
          this.isSecondaryContract = _.get(_data, 'is_secondary') ?? false

          if (this.isSecondaryContract) {
            this.contractRegionGroup = _.get(_data, 'parent.region_groups') || []
          } else {
            this.contractRegionGroup = _.get(_data, 'region_groups') || []
          }

          if (!_.isEmpty(this.contractRegionGroup)) {
            // region
            let regions = this.contractRegionGroup.map(function (item) {
              const region = _.get(item, 'region_meta') || null
              if (region && !_.get(region, 'is_deleted')) {
                return region
              }
            })
            regions = _.orderBy(regions, ['name'], [orderBy])
            regions = _.filter(regions, function (o) {
              if (o) return o
            })
            this.contractRegion = regions.length ? regions : null

            // group
            let groups = this.contractRegionGroup.map(function (item) {
              const group = _.get(item, 'group_meta') || null
              if (group && !_.get(group, 'is_deleted')) {
                return group
              }
            })
            groups = _.orderBy(groups, ['name'], [orderBy])
            groups = _.filter(groups, function (o) {
              if (o) return o
            })
            this.contractGroup = groups.length ? groups : null

            // sub group
            let subGroups = this.contractRegionGroup.map(function (item) {
              const subGroup = _.get(item, 'sub_group_meta') || null
              if (subGroup && !_.get(subGroup, 'is_deleted')) {
                return subGroup
              }
            })
            subGroups = _.orderBy(subGroups, ['name'], [orderBy])
            subGroups = _.filter(subGroups, function (o) {
              if (o) return o
            })
            this.contractSubGroup = subGroups.length ? subGroups : null
          } else {
            this.contractRegion = ''
            this.contractGroup = ''
            this.contractSubGroup = ''
          }

          // eliminate duplicate row from list
          if (_.filter(contracts, { id: _data.id }).length) {
            continue
          } else {
            contracts.push({ id: _data.id })
          }

          const tempData = []
          const contract = _.get(_data, 'contract_no')
          tempData.push(`<a href="/traceability/${_data.id}">${contract}</a>`)
          // This is used as Delivery Month
          let shipmentStartDate = _.get(_data, 'shipment_start_date') ?? 0
          if (shipmentStartDate) {
            shipmentStartDate = moment(shipmentStartDate).format('MMM YYYY')
          } else {
            shipmentStartDate = 'N/A'
          }

          let shipmentEndDate = _.get(_data, 'shipment_end_date') ?? 0
          if (shipmentEndDate) {
            shipmentEndDate = moment(shipmentEndDate).format('MMM YYYY')
          } else {
            shipmentEndDate = 'N/A'
          }
          tempData.push(shipmentStartDate + ' - ' + shipmentEndDate)

          tempData.push(_.get(_data, 'exporter.name') || 'N/A')
          tempData.push(_.get(_data, 'importer.name') || 'N/A')
          tempData.push(_.get(_data, 'customer.name') || 'N/A')
          let containersList = []
          const bols = _.get(_data, 'bol_contracts') || null
          if (bols) {
            _.map(bols, function (item) {
              const containers = _.get(item, 'containers') || null
              if (containers) {
                _.map(containers, function (o) {
                  if (_.get(o, 'container_no')) return containersList.push(_.get(o, 'container_no'))
                })
              }
            })
            containersList = _.uniq(_.sortBy(containersList))
          }
          containersList = containersList.join(', ')
          tempData.push(containersList || 'N/A')
          tempData.push(_.get(_data, 'country_name') || 'N/A')
          const weight = _.get(_data, 'weight') ?? 0
          if (typeof weight === 'number') {
            tempData.push(weight.toLocaleString())
          } else {
            tempData.push(Number(weight).toLocaleString())
          }
          let contractRegion = ''
          const contractRegions = _.uniqWith(this.contractRegion, _.isEqual)
          if (contractRegions.length > 1) {
            contractRegion = 'Multiple regions'
          } else {
            let regionLinks = []
            regionLinks = _.map(contractRegions, function (item) {
              return `<a href='/farmers-region-group/detail/${item.id}/region/'>${item.region_name}</a>`
            })
            contractRegion = regionLinks.join(', ')
          }
          tempData.push(contractRegion || 'N/A')

          let contractGroup = ''
          const contractGroups = _.uniqWith(this.contractGroup, _.isEqual)
          if (contractGroups.length > 1) {
            contractGroup = 'Multiple groups'
          } else {
            let groupLinks = []
            groupLinks = _.map(contractGroups, function (item) {
              return `<a href='/farmers-region-group/detail/${item.id}/group/'>${item.group_name}</a>`
            })
            contractGroup = groupLinks.join(', ')
          }
          tempData.push(contractGroup || 'N/A')

          let contractSubGroup = ''
          const contractSubGroups = _.uniqWith(this.contractSubGroup, _.isEqual)
          if (contractSubGroups.length > 1) {
            contractSubGroup = 'Multiple subgroups'
          } else {
            let subGroupLinks = []
            subGroupLinks = _.map(contractSubGroups, function (item) {
              return `<a href='/farmers-region-group/detail/${item.id}/sub-group/'>${item.sub_group_name}</a>`
            })
            contractSubGroup = subGroupLinks.join(', ')
          }
          tempData.push(contractSubGroup || 'N/A')
          // add original sales link for admin, exporter, importer
          const user = this.$store.getters.getUserInfo
          const role = _.get(user, 'role_name') || ''
          if (role === 'Exporter' || role === 'Importer' || this.$store.getters.iAmAdmin) {
            const originalSalesColumn = _.filter(this.tableHeaders, function (o) {
              if (o.title === 'Original' && o.subtitle === 'Sale') {
                return o
              }
            })
            if (originalSalesColumn.length <= 0) {
              this.tableHeaders.push({
                title: 'Original',
                subtitle: 'Sale',
                db_representation: 'parent',
                orderColumn: 'contract_no'
              })
            }
            const _parent = _.get(_data, 'parent')
            if (!_.isEmpty(_parent) && !_.get(_parent, 'is_secondary')) {
              const contractNo = _.get(_parent, 'contract_no')
              tempData.push(`<a href="/traceability/${_parent.id}">${contractNo}</a>`)
            } else {
              tempData.push('N/A')
            }
          }
          const parsedObj = Array.from(tempData)
          this.tableData.push(parsedObj)
        }
        return this.tableData
        // return _.uniq(this.tableData, function (item, key, a) {
        //   return item.a
        // })
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getContentsFromServer () {
      try {
        this.loading = true
        const limit = this.paginateDict.dataPerPage
        let apiUrl
        if (this.country) {
          apiUrl = `/api/${this.apiPrefix}/?search=1&offset=0&limit=${limit}&exporter__parent__public_code=${this.country.public_code}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        } else {
          apiUrl = `/api/${this.apiPrefix}/?search=1&offset=0&limit=${limit}&sort=${this.orderBy}${this.orderColumnDbRepresentation}`
        }
        const response = await this.$store.dispatch('getUserTraceabilityContractsFromServer', { apiUrl: apiUrl })
        const data = response.data
        if (data.success) {
          if (data.results.length) {
            this.$emit('haveContractData', true)
          } else {
            this.$emit('haveContractData', false)
          }
          this.generatePagination(data)
          this.$store.commit('setTraceabilityContractsContent', data.results)
          await this.prepareTableContents()
        } else {
          this.$emit('haveContractData', 'false')
          this.errorLog(data)
        }
        this.loading = false
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getPaginatedData (apiUrl) {
      this.loading = true
      const response = await this.$store.dispatch('getUserTraceabilityContractsFromServer', { apiUrl: apiUrl })
      const data = response.data
      if (data.success) {
        this.generatePagination(data)
        this.$store.commit('setTraceabilityContractsContent', data.results)
        await this.prepareTableContents()
      } else {
        this.errorLog(data)
      }
      this.loading = false
    },
    async applySearch () {
      const vm = this
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$notify({
          title: '',
          text: 'Please select column.',
          type: 'error',
          duration: 5000
        })
      } else {
        if (vm.searchDict.searchField.widget_type === 'text' || vm.searchDict.searchField.widget_type === 'number') {
          try {
            vm.loading = true
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchParams = `${vm.searchDict.searchField.db_representation}=${vm.searchDict.searchText}`
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch('getUserTraceabilityContractsFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit('setTraceabilityContractsContent', data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
        if (vm.searchDict.searchField.widget_type === 'date_range') {
          try {
            vm.loading = true
            const fromDate = moment(vm.searchDict.fromDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' 00:00:00'
            const toDate = moment(vm.searchDict.toDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' 00:00:00'
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchKeys = _.split(vm.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              const _to = searchKeys[1] || ''
              searchParams = `${_from}=${fromDate},${toDate}&${_to}=${fromDate},${toDate}`
            }
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch('getUserTraceabilityContractsFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit('setTraceabilityContractsContent', data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
        if (vm.searchDict.searchField.widget_type === 'number_range') {
          try {
            vm.loading = true
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchKeys = _.split(vm.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              const _to = searchKeys[1] || ''
              const _fromNumber = vm.searchDict.fromNumber || 0
              const _toNumber = vm.searchDict.toNumber || _fromNumber
              searchParams = `${_from}=${_fromNumber},${_toNumber}&${_to}=${_fromNumber},${_toNumber}`
            }
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch('getUserTraceabilityContractsFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit('setTraceabilityContractsContent', data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
      }
    },
    async applyOrdering (fieldName, orderColumn) {
      if (this.orderBy === '-') {
        this.orderBy = ''
      } else {
        this.orderBy = '-'
      }

      this.orderColumn = orderColumn
      this.orderColumnDbRepresentation = fieldName
      await this.getContentsFromServer()
    }
  },
  async created () {
    await this.getContentsFromServer()
  }
}
</script>

<style lang="scss" scoped>
.nav-item {
  a {
    border-radius: 0;
  }
}

a.page-link:hover {
  cursor: pointer;
}
</style>
