<template>
  <section class="mt-40">
    <div class="container">
      <div class="row">
        <div class="col">
          <div>
            <CountryTab :countries="countries"/>
            <div class="tab-content mt-50">
              <div id="tab-1"
                   :class="{ active: activeTab === 'ALL' }"
                   class="tab-pane fade show" role="tabpanel">
                <all-country-tab-content/>
              </div>
              <div
                v-for="country in countries" :id="'country-tab-' + country.id"
                :key="country.id"
                :class="{ active: activeTab === country.public_code }" class="tab-pane fade show" role="tabpanel">
                <traceability-each-country-tab-content :key="country.id" :country="country"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import TraceabilityEachCountryTabContent from '@/components/user/traceability/EachCountryTabContent'
import AllCountryTabContent from '@/components/user/traceability/AllCountryTabContent'
import CountryTab from '@/components/common/tab/CountryTab'
import UtilityMixin from '@/mixins/utility-mixin'
import _ from 'lodash'

export default {
  name: 'Traceability',
  mixins: [UtilityMixin],
  components: {
    TraceabilityEachCountryTabContent,
    AllCountryTabContent,
    CountryTab
  },
  data () {
    return {
      loading: false,
      activeTab: 'ALL'
    }
  },
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    }
  },
  created () {
  },
  mounted () {
    this.activeTab = this.$route.query.tab || 'ALL'
  },
  methods: {},
  watch: {
    '$route.query.tab' (newValue) {
      this.activeTab = newValue || 'ALL'
    }
  }
}
</script>

<style scoped>

</style>
