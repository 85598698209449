<template>
  <div>
    <content-loader
      v-show="greetingsLoading"
      :height="50"
      :speed="1"
      :width="415"
      primaryColor="#ede9e9"
      secondaryColor="#ecebeb"
    >
      <rect height="10" rx="3" ry="3" width="50%" x="0" y="0"/>
      <rect height="6" rx="3" ry="3" width="100%" x="0" y="15"/>
      <rect height="6" rx="3" ry="3" width="80%" x="0" y="25"/>
    </content-loader>
    <CountryTabContentTopMessage
      v-show="!greetingsLoading"
      :description="greetingsContent.description"
      :title="greetingsContent.title"
    />
    <h3 class="text-primary mt-40 mb-35 font-22">{{ chartTitle }}</h3>
    <div class="row">
      <div class="col-sm-12 col-md-8 col-lg-9 highchart">
        <figure class="highcharts-figure">
          <div id="countries">
            <content-loader
              v-show="chartContentLoading"
              :height="250"
              :speed="1"
              :width="476"
              primaryColor="#ede9e9"
              secondaryColor="#ecebeb"
            >
              <rect height="250" rx="5" ry="5" width="500" x="0" y="0"/>
            </content-loader>
            <FBChart v-show="!chartContentLoading" :chart-content="chartContent"
                     :enable-stack-labels="enableStackLabels" :x-axis-label="getChartXaxisLabel()"></FBChart>
          </div>
        </figure>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-3">
        <div v-show="keyStatsLoading" class="card icon-box mb-3 card-service">
          <div class="card-body align-items-center d-block">
            <content-loader
              :height="100"
              :speed="1"
              :width="476"
              primaryColor="#ede9e9"
              secondaryColor="#ecebeb"
            >
              <rect height="30" rx="3" ry="3" width="50%" x="80" y="10"/>
              <rect height="30" rx="3" ry="3" width="100%" x="80" y="60"/>
              <rect height="50" rx="0" ry="0" width="50" x="-5" y="15"/>
            </content-loader>
          </div>
        </div>
        <small-card-with-icon
          v-for="(item, _i) in keyStatistics"
          v-show="!keyStatsLoading"
          :key="_i"
          :data-unit="item.data_unit"
          :icon-name="item.icon"
          :subtitle="item.title"
          :value="item.subtitle"/>
      </div>
    </div>
    <ContractsTable/>
  </div>
</template>

<script>
import CountryTabContentTopMessage from '@/components/common/tab/CountryTabContentTopMessage'
import ContractsTable from '@/components/user/traceability/ContractsTable'
import _ from 'lodash'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'
import { ContentLoader } from 'vue-content-loader'
import SmallCardWithIcon from '@/components/common/SmallCardWithIcon'
import FBChart from '@/components/common/FBChart'

export default {
  name: 'AllCountryTabContent',
  components: {
    CountryTabContentTopMessage,
    ContractsTable,
    ContentLoader,
    SmallCardWithIcon,
    FBChart
  },
  computed: {
    nkgCountries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    },
    chartTitle () {
      const user = this.$store.getters.getUserInfo
      if (user.role_name === 'Customer') {
        return user.name + '\'s coffee purchases under NKG Bloom'
      } else if (user.role_name === 'Exporter') {
        return user.name + '\'s coffee sales under NKG Bloom'
      } else if (user.role_name === 'Importer') {
        return user.name + '\'s coffee imports under NKG Bloom'
      } else {
        return 'Coffee sales under NKG Bloom'
      }
    }
  },
  data () {
    return {
      loading: true,
      tableHeads: [
        {
          title: 'Contact',
          subtitle: 'Number'
        },
        {
          title: 'Delivery',
          subtitle: 'Month'
        },
        {
          title: 'NKG',
          subtitle: 'Exporter'
        },
        {
          title: 'NKG',
          subtitle: 'Importer'
        },
        {
          title: 'Country',
          subtitle: 'of Origin'
        },
        {
          title: 'Net',
          subtitle: 'Weight (kgs)'
        },
        {
          title: '',
          subtitle: 'Regions'
        },
        {
          title: 'Farmer',
          subtitle: 'group/label'
        },
        {
          title: '',
          subtitle: 'Farmer Subgroup(s)'
        },
        {
          title: '',
          subtitle: 'Additional Info'
        }
      ],
      chartColorScheme: ['#006330', '#69aa1a', '#ed1c24', '#636466', '#66a183', '#a5cd8f', '#f4777c', '#999999', '#bfd8cb', '#d6ebcf', '#fbc6c8', '#cccccc'],
      greetingsLoading: true,
      greetingsContent: { description: '', title: '' },
      keyStatsLoading: true,
      keyStatistics: [],
      chartContentLoading: true,
      chartContent: {},
      importerSoldChartContent: {},
      enableStackLabels: false
    }
  },
  methods: {
    getChartXaxisLabel () {
      if (['Importer'].includes(_.get(this.$store.getters.getUserInfo, 'role_name'))) {
        return '<div style="text-align:center;">&#124;<br /><br />{value}</div>'
      } else {
        return ''
      }
    },
    getChartContents () {
      const _content = this.chartContent
      const _importerSoldContent = this.importerSoldChartContent
      const _allCountryContentList = !_.isEmpty(_content) ? Object.values(_content) : []
      const importerSoldContentList = !_.isEmpty(_importerSoldContent) ? Object.values(_importerSoldContent) : []

      let chartCategories = _.map(_allCountryContentList, function (item) {
        const year = _.get(item, 'year') || 0
        if (year) return year
      })
      const importerSoldChartCategories = _.map(importerSoldContentList, function (item) {
        const year = _.get(item, 'year') || 0
        if (year) return year
      })

      chartCategories = _.sortBy(_.uniq(chartCategories.concat(importerSoldChartCategories)))

      const nkgCountries = this.nkgCountries || []
      const _chartConfig = {
        title: '',
        categories: [],
        series: [],
        stack: ''
      }
      _chartConfig.categories = chartCategories
      let colorIndex = 0
      if (_allCountryContentList.length && _allCountryContentList.length) {
        const totalNkgCountries = nkgCountries.length
        for (let index = 0; index < totalNkgCountries; index++) {
          const _seriesData = []
          const countryObject = nkgCountries[index]
          const countryName = countryObject.name
          // filter out country wise contents
          const _content = _allCountryContentList.filter(item => item.country_name === countryName)
          const _importerSold = importerSoldContentList.filter(item => item.country_name === countryName)
          // For Purchase
          let importDisplayedCountryName = ''
          if (_content.length && countryName) {
            _.map(_chartConfig.categories, function (year) {
              const yearData = _content.filter(item => item.year === year)
              if (yearData.length) {
                _seriesData.push(_.get(yearData[0], 'total_weight'))
              } else {
                _seriesData.push(0)
              }
            })
            if (['Importer'].includes(_.get(this.$store.getters.getUserInfo, 'role_name'))) {
              importDisplayedCountryName = countryName + ' (P)'
            }
            _chartConfig.series.push({
              name: importDisplayedCountryName || countryName,
              color: this.chartColorScheme[colorIndex],
              data: _seriesData,
              stack: 'Purchases'
            })
          }
          // For importer sales
          if (['Importer'].includes(_.get(this.$store.getters.getUserInfo, 'role_name'))) {
            this.enableStackLabels = true
            const _soldSeriesData = []
            if (_importerSold.length && countryName) {
              _.map(_chartConfig.categories, function (year) {
                const yearData = _importerSold.filter(item => item.year === year)
                if (yearData.length) {
                  _soldSeriesData.push(_.get(yearData[0], 'total_weight'))
                } else {
                  _soldSeriesData.push(0)
                }
              })

              _chartConfig.series.push({
                name: countryName + ' (S)',
                color: this.chartColorScheme[colorIndex],
                data: _soldSeriesData,
                stack: 'Sales',
                showInLegend: false
              })
            }
          }
          if (_content.length || _importerSold.length) {
            colorIndex++
          }
        }
      }
      this.chartContent = _chartConfig
    },
    async getTraceabilityKeyStats () {
      this.loading = true
      const response = await this.$store.dispatch('getUserTraceabilityKeyStatsChart')
      const result = _.get(response, 'data') || {}

      const _keyStatsResult = _.get(result, 'key_stats') || {}
      this.chartContent = _.get(result, 'purchased_chart') || {}
      this.importerSoldChartContent = _.get(result, 'sold_chart') || {}

      const isAdminOrExporter = ['SystemAdmin', 'Admin', 'NKG Admin', 'Exporter', 'Observer'].includes(_.get(this.$store.getters.getUserInfo, 'role_name')) || false

      let coffeeWeightTitle = ''
      if (isAdminOrExporter) {
        coffeeWeightTitle = 'NKG Bloom coffee sales'
      } else {
        coffeeWeightTitle = _.get(this.$store.getters.getUserInfo, 'name') + '\'s NKG Bloom purchases'
      }

      let importerTotalSold = []
      if (['Importer'].includes(_.get(this.$store.getters.getUserInfo, 'role_name'))) {
        const totalSoldObj = {
          icon: 'icon_coffee-bean.svg',
          title: _.get(this.$store.getters.getUserInfo, 'name') + '\'s NKG Bloom sales',
          subtitle: _.get(_keyStatsResult, 'total_sold_weight') || 0,
          data_unit: 'kgs'
        }
        importerTotalSold = [totalSoldObj]
      }

      const keyStatObj = {
        icon: 'icon_coffee-bean.svg',
        title: coffeeWeightTitle,
        subtitle: _.get(_keyStatsResult, 'total_weight') || 0,
        data_unit: 'kgs'
      }
      this.keyStatistics = [keyStatObj].concat(importerTotalSold)
      this.loading = false
    },
    async getTraceabilityGreeting () {
      const apiUrl = `/api/v1/content-item/?search=1&app-module=${Menus.traceability.key}&content_type=${ContentTypes.GREETING}`
      const response = await this.$store.dispatch('getContentItemsFromServer', { apiUrl: apiUrl })
      const result = _.get(response, 'data.results') || []
      if (result.length) {
        let _content = result.find((item) => {
          return item.content_type === ContentTypes.GREETING && item.country === null
        })
        _content = _content || {}
        if (_content) {
          this.greetingsContent.title = _.get(_content, 'title') || ''
          this.greetingsContent.description = _.get(_content, 'description') || ''
        }
      }
    }
  },
  mounted () {
  },
  async created () {
    this.greetingsLoading = true
    await this.getTraceabilityGreeting()
    this.greetingsLoading = false
    this.keyStatsLoading = true
    await this.getTraceabilityKeyStats()
    this.keyStatsLoading = false
    this.chartContentLoading = true
    await this.getChartContents()
    this.chartContentLoading = false
  }
}
</script>

<style scoped>

</style>
