<template>
  <div>
    <h3 class="text-primary mb-20 font-30">{{ title }}</h3>
    <h5 v-show="subtitle !== ''" class="text-dark">{{ subtitle }}</h5>
    <p v-show="description !== ''" class="text-pre-wrap" v-html="description"></p>
  </div>
</template>

<script>
export default {
  name: 'CountryTabContentTopMessage',
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
