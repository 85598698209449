<template>
  <traceability></traceability>
</template>

<script>
import Traceability from '@/components/user/traceability/Traceability'

export default {
  name: 'TraceabilityView',
  components: { Traceability }
}
</script>

<style scoped>

</style>
