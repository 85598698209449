<template>
  <div>
    <Chart :options="getChartConfig()"/>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import UtilityMixin from '@/mixins/utility-mixin'
import { Chart } from 'highcharts-vue'

export default {
  name: 'FBChart',
  components: { Chart },
  props: {
    chartContent: {
      type: Object,
      required: true
    },
    xAxisLabel: {
      type: String,
      required: false
    },
    enableStackLabels: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  mixins: [UtilityMixin],
  methods: {
    getChartConfig () {
      const xAxisLabel = this.xAxisLabel || ''
      Highcharts.setOptions({
        lang: {
          thousandsSep: ',',
          decimalPoint: '.'
        }
      })
      return {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: this.chartContent.categories,
          crosshair: true,
          title: {
            text: ''
          },
          labels: {
            format: xAxisLabel,
            useHTML: true,
            y: 30
          }
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          labelFormatter: function () {
            return this.name.replace(' (P)', '')
          }
        },
        yAxis: {
          min: 0,
          // max: 8000,
          tickInterval: 1000,
          title: {
            text: ''
          },
          labels: {
            formatter: function () {
              return Number(this.value).toLocaleString()
            }
          },
          stackLabels: {
            style: {
              color: '#666666',
              fontWeight: 'normal'
            },
            verticalAlign: 'bottom',
            crop: false,
            overflow: 'none',
            y: 20,
            enabled: this.enableStackLabels,
            formatter: function () {
              return this.stack
            }
          }
        },
        navigation: {
          buttonOptions: {
            enabled: false
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size: 14px; font-family:AptiferLight">{point.key}</span><br/>',
          pointFormat: '<span style="color:{point.color}">●</span> <span style="font-family:AptiferLight;">{series.name}: {point.y}</span><br/>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
          positioner: function (boxWidth, boxHeight, point) {
            return { x: point.plotX + 20 }
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 26
          },
          series: {
            stacking: 'normal',
            events: {
              legendItemClick: function (e) {
                e.preventDefault()
              }
            },
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        series: this.chartContent.series
      }
    }
  },
  watch: {},
  computed: {},
  created () {
    this.getChartConfig()
  }
}
</script>

<style scoped>

</style>
